// -----------------------------------------------------------------------------------------------------
// @ Importation
// -----------------------------------------------------------------------------------------------------

@import '../../variables/variables';

// -----------------------------------------------------------------------------------------------------
// @ Mat Dialog
// -----------------------------------------------------------------------------------------------------

.mat-dialog-custom {
  .mat-dialog-container {
    padding: $padding-default-container !important;
    border-radius: $border-radius-default;
  }
}
